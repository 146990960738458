/**
 * TextCard
 * --------
 * extends: Block
 * props:
 *  - title: [string] **required**
 *  - description: [string] **required**
 */

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { themeGet } from 'styled-system';
import { timingFunctions } from 'polished';
import PropTypes from 'prop-types';

import Block from '../../Block';
import { Title, Paragraph } from '../../Text';

const Wrapper = styled(Block)`
  background: ${themeGet('colors.secondary.base')};
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 20px ${themeGet('colors.black.shade.3')};

  @media(min-width: ${themeGet('breakpoints.1')}) {
    min-width: 550px;
  }
`;

const Equalizer = styled(Block)`
  overflow: hidden;
  height: 50px;
`;

const Bar = styled.div`
  position: absolute;
  left: ${props => props.index * 10}px;

  height: 100%;
  transform: translateY(-100%);
  width: 2px;
  background: ${themeGet('colors.white.shade.0')};

  animation:
    equalizer
      2000ms
      ${timingFunctions('easeInOutQuad')}
      ${props => (props.index) * 160}ms
      infinite;

  @keyframes equalizer {
    50%, 100% {
      transform: translateY(100%);
    }
  }
`;

const TextCard = ({ title, description }) => (
  <ThemeProvider theme={{ mode: 'dark' }}>
    <Wrapper p={[4, 5]}>
      <Title>{title}</Title>
      <Paragraph
        as="div"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <Equalizer mt={[3, 4]}>
        {[1, 2, 3, 4, 5].map((_item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Bar key={index} index={index} />
        ))}
      </Equalizer>
    </Wrapper>
  </ThemeProvider>
);

TextCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default TextCard;
