import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { themeGet } from 'styled-system';

import Layout from '../components/Layout';
import Block from '../components/Block';
import { Title, Paragraph } from '../components/Text';
import Sitewidth from '../components/Sitewidth';
import TextBlock from '../components/TextBlock';
import Contact from '../components/Footer/Contact';
import TextCard from '../components/Cards/TextCard';
import CompanyCard from '../components/Cards/CompanyCard';
import Swiping from '../components/Slider/Swiping';
import AnimationHorizontal from '../components/Animate';
import RelatedPortfolios from '../components/RelatedPortfolioItems';

import photo1 from '../assets/images/agency.jpg';
import photo2 from '../assets/images/business.jpg';
import photo3 from '../assets/images/startup.jpg';
import photo4 from '../assets/images/service-1.jpg';
import photo5 from '../assets/images/start-up.jpg';
import photo6 from '../assets/images/product-acc.jpg';
import photo7 from '../assets/images/3d.jpg';

import video1 from '../assets/videos/online.mp4';
import video2 from '../assets/videos/offline.mp4';

import service1 from '../../static/img/service-icon-1.svg';
import service2 from '../../static/img/service-icon-2.svg';
import service3 from '../../static/img/service-icon-3.svg';
import service4 from '../../static/img/service-icon-4.svg';
import service5 from '../../static/img/service-icon-5.svg';
import service6 from '../../static/img/service-icon-6.svg';

const ICONS = [
  { name: 'AR', src: service1 },
  { name: 'NFC', src: service2 },
  { name: 'BEACONS', src: service3 },
  { name: 'WEARABLES', src: service4 },
  { name: 'VR', src: service5 },
  { name: 'GESTURES', src: service6 },
];

const COMPANIES = [
  {
    label: "We're a",
    title: 'Startup',
    url: photo3,
    to: 'startup',
    utcOffset: 0,
  },
  {
    label: "We're a",
    title: 'Company',
    url: photo2,
    to: 'company',
    utcOffset: 240,
  },
  {
    label: "We're an",
    title: 'Agency',
    url: photo1,
    to: 'agency',
    utcOffset: -240,
  },
];

const SERVICE_PLAN = [
  {
    src: photo4,
    type: 'static',
    title: 'Ideation, Strategy, Model',
    description: `A Digital Product is a software enabled product or service
      that offers some form of utility to a human being. The first step of
      building any product or experience starts here. Our business and
      experience teams work together to:
      <br />
      <ul style="list-style: disc; padding-left: 20px;">
        <li>Conceptualize</li>
        <li>Product Roadmap Planing</li>
        <li>Prototyping</li>
        <li>Design The Brand Story</li>
        <li>Develop Strategy And Financial Model</li>
      </ul>
    `,
  },
];

const ONLINE_PRODUCTS = [
  {
    src: video1,
    type: 'video',
    title: 'Online products',
    description: `A digital product can be an online product that is experienced
      through web or mobile such as:
      <br />
      <ul style="list-style: disc; padding-left: 20px;">
        <li>E-Commerce</li>
        <li>Mobile Apps</li>
        <li>Web Apps</li>
        <li>Custom Software</li>
        <li>Web Portals</li>
      </ul>
    `,
  },
];

const OFFLINE_PRODUCTS = [
  {
    src: video2,
    type: 'video',
    title: 'Offline products',
    description: `A digital product can also be an offline product which
    integrates both hardware and software that work as one unified product
    such as:
      <br />
      <ul style="list-style: disc; padding-left: 20px;">
        <li>Directory Kiosks</li>
        <li>Interactive Menu Tables</li>
        <li>Interactive Installations</li>
        <li>Digital Vending Machines</li>
        <li>Digital Stands</li>
      </ul>
    `,
  },
];

const STARTUP_ACCELERATION = [
  {
    src: photo5,
    type: 'static',
    title: 'Startup acceleration',
    description: `The Startup acceleration program helps early- stage teams and
      entrepreneurs embed design thinking into their core DNA
      as they grow and scale.
      <br />
      <br />
      The startup team lives in a DXBee™ design studio for a quarter,
      working to achieve product-market fit. Along the way they’re mentored
      by DXBee™’s core team and participate in the daily life of the studio.
      Going forward, they also benefit from being part of the DXBEE’s family.
      All Startup in Residence candidate companies are referred by DXBEE’s
      network of trusted advisors and investors.
    `,
  },
];

const PRODUCT_ACCELERATION = [
  {
    src: photo6,
    type: 'static',
    title: 'Product acceleration',
    description: `Passionate and got the next big idea, but lack the experience
    and financial ability.
      <br />
      <br />
      We help work with young startups and entrepreneurs to bring their ideas
      to life. We can build your idea as ‘services for equity’ deals which
      means that we will share your risk and invest tin building the product
      for you. A detailed plan has to be submitted, and once qualified,
      our team will contact you on how to begin your journey with us.
    `,
  },
];

const BlockBrandColor = styled(Block)`
  background: ${themeGet('colors.secondary.base')};
`;

const Overflow = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Content = styled(Block)`
  & > :first-child {
    flex-direction: column;
  }
`;

const ImageWrapper = styled(Block)`
  height: 200px;
  overflow: hidden;
  border-radius: 10px;

  @media(min-width: ${themeGet('breakpoints.1')}) {
    height: 500px;
  }

  @media(min-width: ${themeGet('breakpoints.2')}) {
    height: 700px;
  }
`;

const TextCardWrapper = styled.div`
  z-index: 1;
  width: 100%;
  margin-bottom: ${themeGet('space.4')};

  @media(min-width: ${themeGet('breakpoints.1')}) {
    position: absolute;
    right: 10%;
    top: -10%;
    left: auto;
    max-width: 500px;
    margin-bottom: 0;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 10px;
`;

const CompaniesWrapper = styled(Block)`
  background: ${themeGet('colors.secondary.base')};
`;

const StyledTitle = styled(Title)`
  font-size: 1rem !important;
`;

const Companies = styled(Block)`
  flex-direction: column;
  
  & > :not(:last-child) {
    margin-bottom: 20px;
  }

  @media(min-width: ${themeGet('breakpoints.1')}) {
    flex-direction: row;
    width: auto;
    
    & > :not(:last-child) {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
`;

const Niches = styled(Block)`
  background: ${themeGet('colors.secondary.base')};
`;

const IconsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: ${themeGet('space.6')} 0;

  @media(max-width: ${themeGet('breakpoints.2')}) {
    & > :not(:nth-child(n+4)) {
      padding-bottom: 50px;
    }
  }

  @media(max-width: ${themeGet('breakpoints.1')}) {
    & > :not(:nth-child(n+4)) {
      padding-bottom: 25px;
    }
  }
`;

const Icons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media(max-width: ${themeGet('breakpoints.2')}) {
    width: 33%;
    height: 33%;
  }

  @media(max-width: ${themeGet('breakpoints.1')}) {
    width: 50%;
    height: 50%;
    margin-bottom: 30px;
  }

  ${Title} {
    align-items: center;
    width: 100%;
  }
`;

const Icon = styled.img`
  width: 100px;
  height: 100px;
  padding-bottom: 10px;
  margin: 0 auto 20px;
  
  @media(max-width: ${themeGet('breakpoints.1')}) {
    width: 75px;
    height: 75px;
  }
  
  @media(max-width: ${themeGet('breakpoints.0')}) {
    width: 50px;
    height: 50px;
  }
`;

const Normalize = styled.div`
  width: 100%;
`;

const Services = () => (
  <Layout>
    <Overflow>
      <Sitewidth py={[4, 5]}>
        <TextBlock
          title="Services"
          description="We deliver end-to-end concept, design, and engineering
            of the digital product, bringing it through to market launch and
            beyond."
          size="default"
          as="h1"
        />
      </Sitewidth>
      <Content my={[2, 3, 7]} p={[3, 4]}>
        <AnimationHorizontal>
          <TextCardWrapper>
            <AnimationHorizontal direction="btt" delay={200}>
              <TextCard
                title="Design & Experience are recognized as critical factors in
                creating successful products and services."
                description="We merge business, technology and experience to
                  build optimal products. We seeks out partnerships with clients
                  and entrepreneurs rather than the traditional vendor
                  relationship. We integrate ourself within their team and
                  business. Our Team takes on the same emotional commitment to
                  the product’s success as the clients,
                  we’re all in it together."
              />
            </AnimationHorizontal>
          </TextCardWrapper>
          <ImageWrapper>
            <AnimationHorizontal direction="btt" delay={100}>
              <Image src={photo7} alt="" />
            </AnimationHorizontal>
          </ImageWrapper>
        </AnimationHorizontal>
      </Content>
      <CompaniesWrapper py={[5, 6, 7]}>
        <ThemeProvider theme={{ mode: 'dark' }}>
          <Sitewidth
            justifyContent={['center', null, null, 'flex-start']}
            alignItems={['center', null, null, 'flex-start']}
          >
            <AnimationHorizontal direction="btt">
              <TextBlock
                title="Experiences personalized for you"
                quote="- start your journey -"
                color="default"
              />
            </AnimationHorizontal>
            <Companies pt={[5, 6]}>
              {COMPANIES.map((item, index) => (
                <AnimationHorizontal
                  key={item.title}
                  direction="btt"
                  delay={index * 100}
                >
                  <Normalize>
                    <CompanyCard
                      label={item.label}
                      title={item.title}
                      url={item.url}
                      to={`/service/${item.to}`}
                      utcOffset={item.utcOffset}
                      as="h3"
                    />
                  </Normalize>
                </AnimationHorizontal>
              ))}
            </Companies>
          </Sitewidth>
        </ThemeProvider>
      </CompaniesWrapper>
      <Sitewidth py={[5, 6, 7]}>
        <AnimationHorizontal direction="btt">
          <Swiping
            files={SERVICE_PLAN}
          />
        </AnimationHorizontal>
        <RelatedPortfolios currentTags={['Website']} />
      </Sitewidth>
      <ThemeProvider theme={{ mode: 'dark' }}>
        <BlockBrandColor py={[5, 6, 7]}>
          <Sitewidth>
            <AnimationHorizontal direction="btt">
              <Swiping
                color="default"
                files={ONLINE_PRODUCTS}
              />
            </AnimationHorizontal>
            <RelatedPortfolios currentTags={['Website']} />
          </Sitewidth>
        </BlockBrandColor>
      </ThemeProvider>
      <Sitewidth py={[5, 6, 7]}>
        <AnimationHorizontal direction="btt">
          <Swiping
            files={OFFLINE_PRODUCTS}
          />
        </AnimationHorizontal>
        <RelatedPortfolios currentTags={['Website']} />
      </Sitewidth>
      <ThemeProvider theme={{ mode: 'dark' }}>
        <Niches py={[5, 6, 7]}>
          <Sitewidth alignItems="center" textAlign="center">
            <Title pb={[4, 5]}>Our Capabilities</Title>
            <Paragraph maxWidth="700px">
                Well beyond web and mobile, we are ready to engage with emerging
                platforms such as wearables, VR, AR, Gesture, Beacons, NFC and
                beyond to stay at the forefront of digital. In doing all of the
                above we stay ahead of the technology and platform
                commoditization cycle by being able to ‘move to the next’ in a
                timely fashion.
            </Paragraph>
            <IconsWrapper>
              {ICONS.map((icon, index) => (
                <Icons key={icon.name}>
                  <AnimationHorizontal
                    direction="btt"
                    delay={index * 100}
                  >
                    <Icon src={icon.src} />
                  </AnimationHorizontal>
                  <AnimationHorizontal direction="btt" delay={index * 125}>
                    <StyledTitle as="h6">{icon.name}</StyledTitle>
                  </AnimationHorizontal>
                </Icons>
              ))}
            </IconsWrapper>
          </Sitewidth>
        </Niches>
      </ThemeProvider>
      <Sitewidth py={[5, 6, 7]}>
        <AnimationHorizontal>
          <Swiping
            // maxWidth={500}
            order="subtitle"
            label="Co-working space Program"
            files={STARTUP_ACCELERATION}
          />
        </AnimationHorizontal>
        <AnimationHorizontal>
          <Swiping
            // maxWidth={500}
            order="subtitle"
            label="Service for Equity Program"
            files={PRODUCT_ACCELERATION}
            marginBottom={false}
          />
        </AnimationHorizontal>
      </Sitewidth>
      <Contact
        label="Everything digital!"
        title="Our services"
        quote="Revolutionizing your approach is vital. It is virtually
          impossible to outshine your competition without introducing innovative
          tactics; both online and offline. The new consumer expects
          personalized, interactive products & services."
        color="default"
        dataText="Learn more"
        to="/contact"
      />
    </Overflow>
  </Layout>
);

export default Services;
