/**
 * ImageCard
 * ---------
 * extends: Block
 * props:
 *  - title: [string] **required**
 *  - label: [string] **required**
 *  - url: [string] **required**
 *  - to: [string] **required**
 */

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { themeGet } from 'styled-system';
import { timingFunctions } from 'polished';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import Block from '../../Block';
import { Title, Label } from '../../Text';

const Wrapper = styled(Block)`
  min-width: 100%;
  height: 200px;
  border-radius: 10px;
  box-shadow: 0 0 20px ${themeGet('colors.black.shade.3')};
  overflow: hidden;
  cursor: pointer;

  @media(min-width: ${themeGet('breakpoints.1')}) {
    min-width: 210px;
    height: 210px;
  }

  @media(min-width: ${themeGet('breakpoints.2')}) {
    width: 300px;
    height: 300px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;

  background: ${themeGet('colors.black.shade.1')};
  transition: background 1000ms;
  &:hover {
    background: transparent;
  }
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;

  transform: scale(1.1);
  transition: transform 3000ms ${timingFunctions('easeInOut')};
  &:hover {
    transform: scale(1);
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;

  &:hover ${Overlay} {
    transform: translateY(0);
  }

  &:hover ${Image} {
    transform: scale(1);
  }

  &:hover ${Title},
  &:hover ${Label} {
    transition: color 500ms ${timingFunctions('easeInOut')};
    color: ${themeGet('colors.white.base')};
    pointer-events: none;
  }
`;

const ImageCard = ({
  title, label, url, to, as,
}) => (
  <ThemeProvider theme={{ mode: 'dark' }}>
    <AniLink cover to={to} bg="hsl(42,100%,55%)">
      <Wrapper>
        <Content>
          <Image src={url} />
          <Block
            p={[3, 4]}
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Overlay />
            <Label>{label}</Label>
            <Title as={as}>{title}</Title>
          </Block>
        </Content>
      </Wrapper>
    </AniLink>
  </ThemeProvider>
);

ImageCard.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  as: PropTypes.string.isRequired,
};

export default ImageCard;
